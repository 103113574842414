import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@prg/prg-core-lib';
import { environment } from '../environments/environment';

const routes: Routes = [
  {
    path: environment.authModuleConfigurations.authRoutingBasePath,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'checklist',
    loadChildren: () =>
      import('./work-order-checklist/work-order-checklist.module').then(
        (m) => m.WorkOrderChecklistModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'workorderchecklist' }],
  },
  {
    path: 'work-order',
    loadChildren: () =>
      import('./work-order/work-order.module').then((m) => m.WorkOrderModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'workorder' }],
  },

  {
    path: 'user-profile',
    loadChildren: () =>
      import('./user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'userprofile' }],
  },
  {
    path: 'tasks-board',
    loadChildren: () =>
      import('./kanban-board/kanban-board.module').then(
        (m) => m.KanbanBoardModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'list', resource: 'ticket' }],
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'list', resource: 'notification' }],
  },

  /*{
    path: 'dashboard-app',
    loadChildren: () =>
      import('./dashboard/dashboard-app.module').then(
        (m) => m.DashboardAppModule
      ),
    canActivate: [AuthGuard],
  },*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

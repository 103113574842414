import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localePt from '@angular/common/locales/pt-PT';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule } from 'ngx-logger';
import {
  MainInterceptor,
  PageContextService,
  PrgAuthModule,
  PrgConfigurationItemsModule,
  PrgCoreModule,
  PrgDashboardModule,
  PrgDocumentsModule,
  PrgEntityTypesModule,
  PrgLookupTableModule,
  PrgQueriesModule,
  PrgStateModelsModule,
  PrgTermsModule,
  PrgUserManagementModule,
} from '@prg/prg-core-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RippleModule } from 'primeng/ripple';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localePt);
export const HTTP_LOADER_FACTORY = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/locale/', '.json');

const USE_MOCK_SERVICES =
  !environment.production && environment.useMockServices;

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    LoggerModule.forRoot({
      // serverLoggingUrl: `${environment.apiUrl}api/logs`,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
      disableConsoleLogging: false,
    }),
    TranslateModule.forRoot(),
    PrgCoreModule.forRoot({
      useMockServices: USE_MOCK_SERVICES,
      ...environment.coreModuleConfigurations,
    }),
    PrgAuthModule.forRoot(environment.authModuleConfigurations),
    PrgTermsModule.forRoot(environment.termsModuleConfigurations),
    PrgEntityTypesModule.forRoot(environment.entityTypeConfigurations),
    PrgQueriesModule.forRoot(environment.queriesModuleConfigurations),
    PrgLookupTableModule.forRoot(environment.lookupTableConfigurations),
    PrgDashboardModule.forRoot(environment.dashboardModuleConfigurations),
    PrgConfigurationItemsModule.forRoot(
      environment.configurationItemsModuleConfigurations
    ),
    PrgStateModelsModule.forRoot(environment.stateModelsConfigurations),
    PrgUserManagementModule.forRoot(
      environment.userManagementModuleConfigurations
    ),
    RippleModule,
    PrgDocumentsModule.forRoot(environment.documentsModuleConfigurations),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HTTP_LOADER_FACTORY,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: environment.translations.defaultMissingTranslateClassHandler,
      },
    }).providers,
    MessageService,
    ConfirmationService,
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    [{ provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true }],
    PageContextService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  MainLayoutService,
  PrgConfigHeader,
  PrgConfigSidebarMenu,
} from '@prg/prg-core-lib';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from '../environments/environment';
import { App } from '@capacitor/app';
import { SIDEBAR_MENU_APP } from './menus-items-layout/menus-structure-layout';

@Component({
  selector: 'app-root',
  /*templateUrl: './app.component.html',*/
  template: ` <ion-app>
    <ion-content [scrollY]="false">
      <ion-refresher
        style="z-index:calc(var(--prg-z-index-toast) + 5)"
        slot="fixed"
        (ionRefresh)="handleRefresh($event)"
      >
        <ion-refresher-content> </ion-refresher-content>
      </ion-refresher>
      <router-outlet></router-outlet>
    </ion-content>
  </ion-app>`,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private primeNGConfig: PrimeNGConfig,
    private translateService: TranslateService,
    private router: Router,
    private mainLayoutService: MainLayoutService
  ) {
    App.addListener('backButton', async (event) => {
      if (event.canGoBack) {
        window.history.back();
      } else {
        await App.exitApp();
      }
    });
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vhMobileBrowser', `${vh}px`);
    this.mainLayoutService.setSidebarConfig(
      new PrgConfigSidebarMenu({
        mainMenuItems: SIDEBAR_MENU_APP,
        showWorkspaceSelectOnSmallScreens: false,
        maxWidthSidebar: '300px',
      })
    );

    this.mainLayoutService.setHeaderConfig(
      new PrgConfigHeader({
        showWorkspaceSelect: false,
        showUserMenu: false,
        imagePathLogo: '',
        showButtonNotifications: true,
        actionNotificationButton: {
          default: false,
          routerLink: ['/notifications/list'],
        },
      })
    );
  }

  ngOnInit(): void {
    window.console.info('Application Version: ', environment.version);
    this.primeNGConfig.ripple = true;
    this.setupTranslateService();
    const config = this.router.config;
    config.push({
      path: '**',
      redirectTo:
        environment.authModuleConfigurations.authRoutingLoginOkRedirectPath,
      pathMatch: 'full',
    });
    this.router.resetConfig(config);
  }

  private setupTranslateService() {
    /// Configure translation service
    /// {
    // TODO: Get languages and default language from configuration

    this.translateService.langs = ['pt', 'en'];
    let currentLanguage = this.translateService.langs.includes(
      navigator.language
    )
      ? navigator.language
      : this.translateService.langs[0];

    let userLanguageSaved = JSON.parse(
      localStorage.getItem('techparts-solar-language-default')
    );
    if (userLanguageSaved != null) {
      currentLanguage = userLanguageSaved;
    }

    this.setUpLanguage(currentLanguage);
  }

  public setUpLanguage(currentLanguage: string) {
    this.translateService.setDefaultLang(currentLanguage);
    this.translateService.use(currentLanguage);
    this.translateService.onLangChange.subscribe((event: any) => {
      localStorage.removeItem('techparts-solar-language-default');
      localStorage.setItem(
        'techparts-solar-language-default',
        JSON.stringify(event?.lang)
      );
      this.translateService.get('primeng').subscribe((res) => {
        this.primeNGConfig.setTranslation(res);
      });
    });
  }
  handleRefresh(event: any): void {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1500);
  }
}
